export const parseRule = (rule) => {
    var words = rule.split(" ");

    // for now redundant
    return words;
}

export const checkRule = (rule, data) => { // TODO more error throwing for possibly more handling
    const tokens = parseRule(rule);
    
    var [field, operator, value] = tokens;
    
    const dataValue = field.split('.').reduce((acc, part) => acc && acc[part], data);
    
    const typedValue = isNaN(value) ? value : parseFloat(value);
    switch (operator) {
        case '>': return dataValue > typedValue;
        case '<': return dataValue < typedValue;
        case '==': return dataValue == typedValue;
        case '!=': return dataValue != typedValue;
        case '>=': return dataValue >= typedValue;
        case '<=': return dataValue <= typedValue;
        default: throw new Error(`unsupported operator: ${operator}`);
    }
}