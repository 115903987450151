import axios from "axios";

const ENVMODE = process.env.REACT_APP_MODE;
const BASEURLS = {
    'development': 'http://localhost:8080',
    'staging': 'http://starchild-api-staging.us-east-1.elasticbeanstalk.com',
    'production': 'https://api.starchildmusic.net',
    'mongo': 'http://localhost:5000'
};
const BASE_URL = BASEURLS[ENVMODE];

export const login = async (email, password) => {
    try {
        const response = await axios.get(`${BASEURLS['mongo']}/fans/email/` + email);
        const fan = response.data;

        // Placeholder for password verification logic
        // Needs to be done securely on the server
        if (fan && fan.auth && fan.auth.email && fan.auth.email.password === password) {
            console.log("Login successful", fan);
            return {
                fan: fan,
                message: "Login successful"
            };
        } else {
            console.error("Login failed: Invalid password or email");
            throw new Error("Invalid password or email");
        }
    } catch (error) {
        console.error("Login failed:", error);
        throw new Error(error.response?.data?.message || "An error occurred during login.");
    }
};

export const register = async (displayName, email, password) => {
    try {
        let fanObject = {
            displayName: displayName,
            email: email,
            auth: {
                email: {
                    isMethod: true,
                    password: password
                },
                google: false
            },
            library: {},
            fanSince: new Date()
        };

        const res = await axios.post(`${BASEURLS['mongo']}/fans`, fanObject);
        if (res.status === 201) {
            // Fan added to database successfully
            console.log(res.data);
            return {
                fan: res.data,
                message: "Success: Your fan account has been created!"
            };
        } else {
            return { message: "Error Occured with Registering" };
        }
    } catch (error) {
        console.error("Registration failed:", error);
        throw new Error(error.response?.data?.message || "An error occurred during registration.");
    }
};