/* Third Party Dependencies */
import { Flex, Hide, Show, Spacer, Text } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'

/* Local Dependencies */
import Blob from '../atoms/Blob'
import Link from '../atoms/Link'
import Logo from '../atoms/Logo'



const PreAuthTemplate = (props) => {
    const { children, links, title, subtitle } = props

    const location = useLocation()
    const navigate = useNavigate()

    const handleLogoClick = () => {
        navigate('/')
    }

    return (
        <Flex
            direction={{ base: 'column-reverse', lg: 'row' }}
            height={{ base: 'auto', lg: '100vh' }}
            whiteSpace={'initial'}
            width="100vw"
        >
            <Flex
                backgroundColor={'gray.900'}
                overflow={'hidden'}
                padding={{ base: 8, lg: 16 }}
                position={'relative'}
                maxWidth={{ base: '100vw', lg: '45vw', xl: '33vw' }}
            >
                <Flex direction={'column'} height={'full'} gap={4}>
                    <Hide below='lg'>
                        <Logo onClick={handleLogoClick} />
                    </Hide>
                    <Spacer />
                    <Text
                        as='h1'
                        color={'gray.50'}
                        fontSize={{ base: 40, lg: 48 }}
                        fontWeight={'medium'}
                        lineHeight={1.2}
                        zIndex={1}
                    >
                        <Show above='lg'>
                            Make <br /> music <br /> <strong>interactive</strong>
                        </Show>
                        <Hide above='lg'>
                            Make music <strong>interactive</strong>
                        </Hide>
                    </Text>
                    <Text
                        color='gray.300'
                        fontSize={28}
                        zIndex={1}
                    >
                        Personalized music experiences for your fans
                    </Text>
                    <Spacer />
                    <Spacer />
                    <Flex
                        position={'absolute'}
                        bottom={-72}
                        left={-8}
                        right={-8}
                        zIndex={0}
                    >
                        <Blob />
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                backgroundColor={'gray.50'}
                flexDirection={'column'}
                flexGrow={1}
                justify={'center'}
                padding={{ base: 8, lg: 16, xl: 64 }}
                paddingTop={{ base: 16 }}
                position={'relative'}
            >
                <Flex
                    align={'center'}
                    color={'gray.600'}
                    direction={'row'}
                    gap={4}
                    height={12}
                    justify={'right'}
                    left={0}
                    paddingX={{ base: 8, lg: 16 }}
                    paddingTop={{ base: 8, lg: 16 }}
                    position={'absolute'}
                    right={0}
                    top={0}
                    width={'full'}
                >
                    <Show below='lg'>
                        <Logo height={12} onClick={handleLogoClick} />
                    </Show>
                    <Spacer />
                    {links.map(link => (
                        <Link key={link.resource} route={link} selected={location.pathname === link.resource} />
                    ))}
                </Flex>
                <Spacer />
                <Flex direction={'column'} mb={4}>
                    {title != null &&
                        <Text
                            as={'h1'}
                            color={'gray.900'}
                            fontSize={{ base: 40, lg: 48 }}
                            fontWeight={'bold'}
                        >
                            {title}
                        </Text>
                    }
                    {subtitle != null &&
                        <Text color={'gray.500'} fontSize={20} fontWeight={'light'}>
                            {subtitle}
                        </Text>
                    }
                </Flex>
                {children}
                <Spacer />
            </Flex>
        </Flex>
    )
}

PreAuthTemplate.defaultProps = {
    links: [
        { name: 'Log In', resource: '/' },
        { name: 'Register', resource: '/register' }
    ],
    title: null,
    subtitle: null
};

export default PreAuthTemplate