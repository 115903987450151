import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    useToast,
} from "@chakra-ui/react";
import PasswordInput from "../../atoms/PasswordInput";
import PreAuthTemplate from "../../templates/PreAuthTemplate";
import { login } from "../../../api/auth";

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const toast = useToast();
    const navigate = useNavigate();

    const validateEmail = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
            setEmailError("Invalid email address");
            return false;
        }
        setEmailError("");
        return true;
    };

    const validatePassword = () => {
        // Add any password validation you might need here
        return true;
    };

    const handleLogin = async () => {
        if (!validateEmail() || !validatePassword()) {
            // Handle invalid input
            return;
        }

        try {
            const response = await login(email, password);
            if (response.message === 'Login successful') {
                toast({
                    title: "Login Successful",
                    description: "You are now logged in.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/HomePage'); // Navigate to HomePage upon successful login
            } else {
                toast({
                    title: "Login Failed",
                    description: "Please check your credentials.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Login failed:", error);
            toast({
                title: "Login Failed!",
                description: "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <PreAuthTemplate title="Login">
            <Flex direction={"column"} gap={4} width={"full"}>
                <FormControl isRequired isInvalid={!!emailError}>
                    <FormLabel>Email Address</FormLabel>
                    <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormErrorMessage>{emailError}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!passwordError}>
                    <FormLabel>Password</FormLabel>
                    <PasswordInput
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormErrorMessage>{passwordError}</FormErrorMessage>
                </FormControl>

                <Button onClick={handleLogin} mt={2}>
                    Login
                </Button>
            </Flex>
        </PreAuthTemplate>
    );
};

export default LoginPage;