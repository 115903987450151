import React, { createContext, useState, useEffect } from 'react';

export const PhysicalDataContext = createContext({
  speed: null,
  location: { lat: null, lng: null },
  orientation: {
    direction: '',
    orientationData: { alpha: null, beta: null, gamma: null, realAlpha: null }
  }
});

/* Take note:
   Orientation seems to routinely get wiped, so it can only reflect changes in rotation.
   If the user is perfectly still (which won't be the case when they use the app), it will
   be unavailable. Luckily, we only need orientation to detect changes in rotation.
*/
export const PhysicalDataProvider = ({ children }) => {
  const [PhysicalData, setPhysicalData] = useState({
    speed: null,
    location: { lat: null, lng: null },
    orientation: {
      direction: '',
      orientationData: { alpha: null, beta: null, gamma: null, realAlpha: null }
    }
  });

  const handleGeolocationError = (error) => {
    console.warn(`ERROR(${error.code}): ${error.message}`);
    setPhysicalData(prevData => ({
      ...prevData,
      error: `ERROR(${error.code}): ${error.message}`
    }));
  };
  
  const handleGeolocationSuccess = (position) => {
    const { latitude, longitude, speed } = position.coords;
    setPhysicalData({
      speed: speed,
      location: { lat: latitude, lng: longitude },
    });
  };

  const handleOrientation = (event) => {

    const rawAlpha = event.alpha;
    const rawBeta = event.beta;
    const rawGamma = event.gamma;

    const alpha = rawAlpha !== null ? parseFloat(rawAlpha.toFixed(1)) : null;
    const beta = rawBeta !== null ? parseFloat(rawBeta.toFixed(1)) : null;
    const gamma = rawGamma !== null ? parseFloat(rawGamma.toFixed(1)) : null;
    const realAlpha = alpha !== null ? 360 - alpha : null;

    let direction = 'Unknown';
    if (realAlpha !== null) {
      if (realAlpha > 337.5 || realAlpha <= 22.5) {
        direction = 'North';
      } else if (realAlpha > 22.5 && realAlpha <= 67.5) {
        direction = 'North-East';
      } else if (realAlpha > 67.5 && realAlpha <= 112.5) {
        direction = 'East';
      } else if (realAlpha > 112.5 && realAlpha <= 157.5) {
        direction = 'South-East';
      } else if (realAlpha > 157.5 && realAlpha <= 202.5) {
        direction = 'South';
      } else if (realAlpha > 202.5 && realAlpha <= 247.5) {
        direction = 'South-West';
      } else if (realAlpha > 247.5 && realAlpha <= 292.5) {
        direction = 'West';
      } else if (realAlpha > 292.5 && realAlpha <= 337.5) {
        direction = 'North-West';
      }
    }

    setPhysicalData(prevData => ({
      ...prevData,
      orientation: {
        direction: direction,
        orientationData: { alpha, beta, gamma, realAlpha }
      }
    }));
  };

  useEffect(() => {

    const updateInterval = 100;
    
    window.addEventListener('deviceorientation', handleOrientation);

    if ("geolocation" in navigator) {
      navigator.geolocation.watchPosition(handleGeolocationSuccess, handleGeolocationError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: updateInterval,
      });
    } else {
      console.log("geolocation not supported");
    }

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, []);

  return (
    <PhysicalDataContext.Provider value={PhysicalData}>
      {children}
    </PhysicalDataContext.Provider>
  );
};