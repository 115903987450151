import './App.css';

import LoginPage from './components/pages/pre-auth/LoginPage.jsx';
import HomePage from './components/pages/pre-auth/HomePage.jsx';
import DrivePage from './components/pages/pre-auth/DrivePage.jsx';
import PlayPage from './components/pages/pre-auth/PlayPage.jsx';
import RunPage from './components/pages/pre-auth/RunPage.jsx';
import RegisterPage from './components/pages/pre-auth/RegisterPage.jsx';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>Last merge: Routing for Multiple Pages (Home, Register, Login, NoPageFound)</h3>

        <BrowserRouter>
          <Routes>
            {/* Redirect to login as default route */}
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/HomePage" element={<HomePage />} />
            <Route path="/DrivePage" element={<DrivePage />} />
            <Route path="/PlayPage" element={<PlayPage />} />
            <Route path="/RunPage" element={<RunPage />} />
            <Route path="/register" element={<RegisterPage />} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;