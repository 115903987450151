import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    useToast,
    FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../../../components/atoms/PasswordInput";
import PreAuthTemplate from "../../templates/PreAuthTemplate";
import { register } from "../../../api/auth";

const RegisterPage = () => {
    const navigate = useNavigate();
    const toast = useToast();

    // State variables for input values and error messages
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [displayNameError, setDisplayNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    // Function to validate the form fields
    const validateForm = () => {
        let isValid = true;

        if (!displayName) {
            setDisplayNameError("Display Name is required");
            isValid = false;
        } else {
            setDisplayNameError("");
        }

        if (!email) {
            setEmailError("Email is required");
            isValid = false;
        } else if (
            !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
        ) {
            setEmailError("Invalid email address");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError("Password is required");
            isValid = false;
        } else if (password.length < 8) {
            setPasswordError("Password must be at least 8 characters");
            isValid = false;
        } else {
            setPasswordError("");
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            isValid = false;
        } else {
            setConfirmPasswordError("");
        }

        return isValid;
    };

    async function handleRegister() {
        try {
            // Validate the form
            if (!validateForm()) {
                return;
            }

            // Make a POST request to the register API endpoint
            const response = await register(
                displayName,
                email,
                password
            );

            console.log(response);

            // The API returns a success message
            const message = response.message;
            toast({
                title: "User Created!",
                description: "Your fan account has been created!",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            // Display the success message
            console.log(message);

            // Navigate to the home page
            navigate('/HomePage');
        } catch (error) {
            console.error("Registration failed:", error);
        }
    };

    return (
        <PreAuthTemplate title="Register">
            <Flex direction={"column"} gap={4} width={"full"}>
                {/* Display Name */}
                <FormControl isRequired isInvalid={!!displayNameError}>
                    <FormLabel>Display Name</FormLabel>
                    <Input
                        id="displayName"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                    <FormErrorMessage>{displayNameError}</FormErrorMessage>
                </FormControl>

                {/* Email */}
                <FormControl isRequired isInvalid={!!emailError}>
                    <FormLabel>Email Address</FormLabel>
                    <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormErrorMessage>{emailError}</FormErrorMessage>
                </FormControl>

                {/* Password */}
                <FormControl isRequired isInvalid={!!passwordError}>
                    <FormLabel>Password</FormLabel>
                    <PasswordInput
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormErrorMessage>{passwordError}</FormErrorMessage>
                </FormControl>

                {/* Confirm Password */}
                <FormControl isRequired isInvalid={!!confirmPasswordError}>
                    <FormLabel>Confirm Password</FormLabel>
                    <PasswordInput
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
                </FormControl>

                {/* Submit Button */}
                <Button
                    onClick={() => {
                        handleRegister();
                    }}
                    mt={2}
                >
                    Create Account
                </Button>
            </Flex>
        </PreAuthTemplate>
    );
};

export default RegisterPage;
