/* Third Party Dependencies */
import { Image, ImageProps } from '@chakra-ui/react'

/* Local Dependencies */
import logo from '../../core/assets/logo.png'


const Logo = (props) => {
    const getImage = () => logo
    return (
        <Image
            fit={'contain'}
            height={24}
            objectPosition={'left'}
            src={getImage()}
            _hover={{
                cursor: 'pointer'
            }}
            {...props}
        />
    )
}

export default Logo