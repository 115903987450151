/* Third Party Dependencies */
import { useState } from 'react'
import { Input, InputProps, InputGroup, InputRightElement } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

const PasswordInput = (props) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  return (
    <InputGroup>
      <Input
        pr={16}
        type={show ? 'text' : 'password'}
        {...props}
      />
      <InputRightElement>
        {show
          ? <ViewOffIcon color={'gray.500'} onClick={handleClick} _hover={{ cursor: 'pointer' }} />
          : <ViewIcon color={'gray.500'} onClick={handleClick} _hover={{ cursor: 'pointer' }} />
        }
      </InputRightElement>
    </InputGroup>
  )
}

export default PasswordInput