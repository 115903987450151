import { React, useContext, useState, createContext, useEffect, useEventEmitter } from 'react';
import { EventContext } from './EventContext.js';

export default function EventTestDisplay() {
    const [message, setMessage] = useState('awaiting event');
    const { eventsList } = useContext(EventContext);

    useEffect(() => {
        if (eventsList) {
            setMessage(eventsList.join(', '));
        }
    }); 
    return <div>{message}</div>;
}
