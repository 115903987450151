import React, { useState, createContext, useContext, useEffect } from 'react';
import { PhysicalDataContext } from './physical/PhysicalDataContext.js';
import { EnergyLevelContext } from './physical/EnergyLevelContext.js';

export const EventContext = createContext({
    lastEvent: 'init'
});

export const EventProvider = ({ children }) => {

    const [EventData, setEventData] = useState({
        lastEvent: 'init'
    });

    const { speed, location, orientation } = useContext(PhysicalDataContext);
    const {runEnergy, driveEnergy} = useContext(EnergyLevelContext);

    // Events checked for when Drive energy level changes
    useEffect(() => {
        if (speed < 1){
            const lastEvent = "drive-stop";
            setEventData(prevData => ({...prevData, lastEvent: lastEvent}));
        }
    }, [driveEnergy]);

    return (
        <EventContext.Provider value={EventData}>
            <div>
                Most recent event: {EventData.lastEvent}<br />
            </div>
            <br />
            {children}
        </EventContext.Provider>
    );
};