import React, { createContext, useState, useEffect, useContext } from 'react';
import { PhysicalDataContext } from './PhysicalDataContext.js';

export const EnergyLevelContext = createContext({
  runEnergy: 'Lowest',
  driveEnergy: 'Lowest'
});

export const EnergyLevelProvider = ({ children }) => {
  const [EnergyLevel, setEnergyLevel] = useState({
    runEnergy: 'Lowest',
    driveEnergy: 'Lowest'
  });

  const { speed } = useContext(PhysicalDataContext);

  useEffect(() => {
    const driveEnergy = determineDrive(speed);
    const runEnergy = determineRun(speed);
    setEnergyLevel({ driveEnergy, runEnergy });
  }, [speed]);

  const determineDrive = (speed) => {
    if (speed < 17 * 0.44704) { // under 17mph
      return 'Lowest';
    } else if (speed < 35 * 0.44704) { // under 35mph
      return 'Lowish';
    } else if (speed < 55 * 0.44704) { // under 55mph
      return 'Medium';
    } else { // over 55mph
      return 'High';
    }
  };

  const determineRun = (speed) => {
    if (speed < 4 * 0.44704) {
      return 'Lowest'; // Stopped or walking
    } else if (speed < 6 * 0.44704) {
      return 'Lowish'; // Jogging
    } else if (speed < 10 * 0.44704) {
      return 'Medium'; // Running
    } else { 
      return 'High'; // Sprinting
    }
  };

  return (
    <EnergyLevelContext.Provider value={EnergyLevel}>
      {children}
    </EnergyLevelContext.Provider>
  );
};
