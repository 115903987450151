export const physicalToEvents = (speed, location, orientation) => {
  // placeholder until i figure out how we load rules
    var eventList = []
    if (location.lat > 0) {
      eventList.push("lat > 0")
    } else if (location.lat < 0) {
      eventList.push("lat < 0")
    }
    if (location.lng > 0) {
      eventList.push("lng > 0")
    } else if (location.lng < 0) {
      eventList.push("lng < 0")
    }
    
    if (speed > 0.1) {
      eventList.push("forwards")
    } else if (speed == 0) {
      eventList.push("stopped")
    } else if (speed < -0.1) {
      eventList.push("backwards")
    }

    var rotation = orientation?.orientationData.realAlpha !== null ? orientation?.orientationData.realAlpha.toFixed(3) : 'unavailable'

    if (rotation > 180) {
      eventList.push("rotation more than 180")
    }
    return eventList;
};