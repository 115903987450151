import { useContext } from 'react';
import { EnergyLevelContext } from './EnergyLevelContext.js';

function EnergyLevelDisplay() {
    const { runEnergy, driveEnergy } = useContext(EnergyLevelContext);

    return (
        <div>
            <p>
                Running Energy: {runEnergy ? `${runEnergy}` : 'unavailable'}<br />
                Driving Energy: {driveEnergy ? `${driveEnergy}` : 'unavailable'}<br />
            </p>
        </div>
    )
}

export default EnergyLevelDisplay;
