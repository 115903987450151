import React, { useState, useEffect, useRef, useContext } from 'react';
import { EnergyLevelContext } from './physical/EnergyLevelContext.js';
import { EventContext } from './EventListener.js';

const Simulation = () => {
    const [audioEnabled, setAudioEnabled] = useState(false);
    const [mode, setMode] = useState("Run"); // "Run" or "Drive"
    const musicRef = useRef(new Audio("audio/lowest.wav"));
    const soundFXRef = useRef(new Audio("audio/honk-honk.mp3"));
    const [currentAudioSource, setCurrentAudioSource] = useState("audio/lowest.wav");

    const { runEnergy, driveEnergy } = useContext(EnergyLevelContext);
    const { lastEvent } = useContext(EventContext);

    useEffect(() => {
        const newAudioSource = energyAudioSwitch(mode === "Run" ? runEnergy : driveEnergy);
        if (currentAudioSource !== newAudioSource) {
            setCurrentAudioSource(newAudioSource);
            const music = musicRef.current;
            music.src = newAudioSource;
            if (audioEnabled) {
                music.play().catch(err => console.error("Error playing audio:", err));
            }
        }
    }, [runEnergy, driveEnergy, mode, audioEnabled, currentAudioSource]);

    // Toggle audio enabled state
    const toggleAudio = () => {
        setAudioEnabled(!audioEnabled);
        if (!audioEnabled) {
            musicRef.current.play().catch(err => console.error("Error playing audio:", err));
        } else {
            musicRef.current.pause();
        }
    };

    // Switch mode between "Run" and "Drive"
    const switchMode = () => setMode(prevMode => (prevMode === "Run" ? "Drive" : "Run"));

    useEffect(() => {
        if (lastEvent === "drive-stop" && audioEnabled) {
            soundFXRef.current.play().catch(err => console.error("Error playing soundFX:", err));
        }
    }, [lastEvent, audioEnabled]);

    const energyAudioSwitch = (energy) => {
        switch (energy) {
            case "Lowish":
                return 'audio/lowish.wav';
            case "Medium":
                return 'audio/medium.wav';
            case "High":
                return 'audio/high.wav';
            default:
                return 'audio/lowest.wav';
        }
    };

    return (
        <div>
            Mode: {mode}<br />
            <button type="button" onClick={switchMode}>Switch Mode</button><br />
            <button type="button" onClick={toggleAudio}>
                {audioEnabled ? "Disable Audio" : "Enable Audio"}
            </button>
        </div>
    );
};

export default Simulation;
