/* Third Party Dependencies */
import { Link as ReactRouterLink } from 'react-router-dom'
import { Flex, Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { RxExternalLink } from 'react-icons/rx'



const Link = (props) => {
    const { route, isExternal, selected, ...rest } = props
    return (
        <ChakraLink
            as={ReactRouterLink}
            fontWeight={selected ? 'bold' : 'regular'}
            isExternal={isExternal}
            target={isExternal ? '_blank' : undefined}
            to={props.route.resource}
            {...rest}
        >
            <Flex alignItems={'center'} direction={'row'}>
                {route.name}
                {isExternal && <RxExternalLink style={{ marginLeft: '0.25rem', width: '1rem', height: '1rem' }} />}
            </Flex>
        </ChakraLink>
    )
}

Link.defautProps = {
    isExternal: false,
    selected: false,
    style: {}
}

export default Link