// HomePage.jsx
import React from 'react';
import RuleParserDisplay from '../../../events/RuleDisplay.js';
import { useNavigate } from 'react-router-dom';

import './NavigationBoxes.css';

const HomePage = () => {

    const navigate = useNavigate();

    const navigateToPage = (page) => {
        navigate(`/${page}`);
    };

    return (
        <>
            <RuleParserDisplay />
            <div className="navigation-boxes">
                <button onClick={() => navigateToPage('HomePage')}>HomePage</button>
                <button onClick={() => navigateToPage('DrivePage')}>DrivePage</button>
                <button onClick={() => navigateToPage('RunPage')}>RunPage</button>
                <button onClick={() => navigateToPage('PlayPage')}>PlayPage</button>
            </div>
        </>
    );
};

export default HomePage;