import { React, useContext, useState, createContext, useEffect, useEventEmitter } from 'react';
import { jsonToRule } from './ruleParser';
import { checkRule } from './ruleChecker';

const samplePData = {
    speed: 20,
    location: { lat: 37.778, lng: -122.467 },
    orientation: {
        direction: '',
        orientationData: { alpha: 200, beta: null, gamma: null, realAlpha: null }
    }
};

const sampleRule = "speed > 10";

const sampleRuleJson = 
`{
    "id": "basic01",
    "description": "sample ruleset, basic speed and accel",
    "rules": [
        {
            "conditions": ["speed > 0"],
            "effect": "moving"
        },
        {
            "conditions": ["speed <= 0.5"],
            "effect": "stopped"
        },
        {
            "conditions": ["accel < 2", "speed > 50"],
            "effect": "cruise"
        },
        {
            "conditions": ["speed > 10", "speed < 30", "orientation.orientationData.alpha > 10", "location.lat > 20", "location.lng < 0"],
            "effect": "big benjamins"
        },
        {
            "conditions": ["dog < 5", "bingus < bongus"],
            "effect": "doggers"
        }
    ]
}`;

// pretty prints text with new lines, particular stringified JSON
const NewlineText = ({ text }) => {
    const newText = text.split('\n').map((str, index, array) =>
        <span key={index}>{str}{index === array.length - 1 ? '' : <br />}</span>
    );

    return <div
        style={{ 
            whiteSpace: 'pre-wrap',
            fontSize: '20px',
            textAlign: 'left'
        }}
    >{newText}</div>;
};

// generates rule list based on rules and data
const RuleList = ({ parsedRules, data }) => {
    
    const ruleResults = parsedRules.rules.map((rule) => {
      const isRuleTrue = rule.conditions.every(condition => checkRule(condition, data));
      return {
        effect: rule.effect,
        result: isRuleTrue
      };
    });
  
    return (
      <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', gap: '10px' }}>
        {ruleResults.map((ruleResult, index) => (
          <div key={index} style={{ 
            display: 'contents',
            color: ruleResult.result ? 'palegreen' : 'lightcoral' 
          }}>
            <div style={{ textAlign: 'right', paddingRight: '5px' }}>{ruleResult.effect}</div>
            <div>-></div>
            <div style={{ textAlign: 'left', paddingLeft: '5px' }}>{ruleResult.result.toString()}</div>
          </div>
        ))}
      </div>
    );
};

export default function RuleParserDisplay() {
    const parsedRules = jsonToRule(sampleRuleJson);
    const parsedString = JSON.stringify(parsedRules, null, 4);
    const dataString = JSON.stringify(samplePData, null, 4);
    const ruleCheck = checkRule(sampleRule, samplePData);

    for (var rule of parsedRules["rules"]) {
        for (var cond of rule["conditions"]) {
            checkRule(cond, samplePData)
        }
    }

    return <div>
            <hr /><br />
            <b>parsed rules</b><br />
            <NewlineText text={parsedString} /><br />
            <b>sample data</b><br />
            <NewlineText text={dataString} />
            <b>effects evaluation</b>
            <RuleList parsedRules={parsedRules} data={samplePData} /><br />
            <hr />
            </div>;
}