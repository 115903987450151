import React, { createContext, useState, useEffect, useContext } from 'react';
import { PhysicalDataContext } from '../physical/PhysicalDataContext.js';
import { physicalToEvents } from './eventBox.js'

export const EventContext = createContext({
  eventsList: [],
  rules: null
});

export const EventContextProvider = ({ children }) => {
  const [Event, setEvents] = useState(["no events yet"]);

  const { speed, location, orientation } = useContext(PhysicalDataContext);

  useEffect(() => {
    setEvents(physicalToEvents(speed, location, orientation))
  }, [speed, location, orientation]);

  useEffect(() => {
    // no dependency, initially load rules here
  }, [])
  return (
    <EventContext.Provider value={{ eventsList: Event }}>
      {children}
    </EventContext.Provider>
  );
};
