import { useContext } from 'react';
import { PhysicalDataContext } from './PhysicalDataContext.js';

function PhysicalDataDisplay() {
    const { speed, location, orientation } = useContext(PhysicalDataContext);

    return (
        <div>
            <p>
                speed: {speed !== null ? (speed * 2.23694).toFixed(3) + ' mph' : 'unavailable'}<br />
                latitude: {location.lat !== null ? location.lat.toFixed(3) : 'unavailable'}<br />
                longitude: {location.lng !== null ? location.lng.toFixed(3) : 'unavailable'}<br />
                rotation: {orientation?.orientationData.realAlpha !== null ? orientation?.orientationData.realAlpha.toFixed(3) : 'unavailable'}<br />
            </p>
        </div>

    )
}

export default PhysicalDataDisplay;
