// HomePage.jsx
import React from 'react';
import { PhysicalDataProvider } from '../../../physical/PhysicalDataContext.js'
import PhysicalDataDisplay from '../../../physical/PhysicalDataDisplay.js';
import { EnergyLevelProvider } from '../../../physical/EnergyLevelContext.js';
import EnergyLevelDisplay from '../../../physical/EnergyLevelDisplay.js';
import { EventContextProvider } from '../../../events/EventContext.js';
import EventTestDisplay from '../../../events/EventContextDisplay.js';
import { EventProvider } from '../../../EventListener.js';
import Simulation from '../../../Simulation.js';
import { useNavigate } from 'react-router-dom';
import './NavigationBoxes.css';

const HomePage = () => {

    const navigate = useNavigate();

    const navigateToPage = (page) => {
        navigate(`/${page}`);
    };

    return (
        <>
            <PhysicalDataProvider>
                <EventContextProvider>
                    <EventTestDisplay />
                </EventContextProvider>
                <PhysicalDataDisplay />
                <EnergyLevelProvider>
                    <EnergyLevelDisplay />
                    <EventProvider>
                        <Simulation />
                    </EventProvider>
                </EnergyLevelProvider>
            </PhysicalDataProvider>
            <div className="navigation-boxes">
                <button onClick={() => navigateToPage('HomePage')}>HomePage</button>
                <button onClick={() => navigateToPage('DrivePage')}>DrivePage</button>
                <button onClick={() => navigateToPage('RunPage')}>RunPage</button>
                <button onClick={() => navigateToPage('PlayPage')}>PlayPage</button>
            </div>
        </>
    );
};

export default HomePage;